import logo from "../images/logo.png"
import hero_1 from "../images/hero-1.jpg"
import cmo_1 from "../images/ADRISTY YUMI ANDINI_CMO.png"
import cmo_2 from "../images/aisyah nur sadjadah_ CMO.png"
import ceo from "../images/diki darmawan _ CEO.png"
import cto from "../images/ILHAM DWI PAMUNGKAS _CTO.png"
import coo from "../images/MUHAMMAD RAYENDRA_COO.png"
import hp from "../images/Group 19.png"
import fitur_chat from "../images/foto produk unitycamp/fitur chat.jpg"
import fitur_diskusi from "../images/foto produk unitycamp/fitur diskusi.jpg"
import fitur_pencarian from "../images/foto produk unitycamp/fitur pencarian.jpg"
import video_random from "../images/foto produk unitycamp/video random.jpg"
import dc from "../images/discord.png"
import about from "../images/2.jpg"
import {useState, useEffect} from 'react'
import {motion, useAnimation} from 'framer-motion'
import { useInView } from "react-intersection-observer"

export default function Pages(){
    
    const [sidebar, setSideBar] = useState(false);
    const control = useAnimation();
    const [ref, inView] = useInView();
    function sidebarHandler(){
        setSideBar(!sidebar);
    }

    const animationVariants = {
        hidden: { opacity: 0, y: "200px"},
        visible: { opacity: 1, y:"0", transition:{
                                                    type : "just",
                                                    mass : 1,
                                                    damping : 1,
                                                    when : "beforeChildren",
                                                    staggerChildren:0.4,
                                                    duration : 1
        }}
    };

    const animationVariants_1 = {
        hidden: { opacity: 0, y: "200px"},
        visible: { opacity: 1, y:"0", transition:{
                                                    type : "just",
                                                    mass : 1,
                                                    damping : 1,
                                                    when : "beforeChildren",
                                                    staggerChildren:0.4,
                                                    duration : 1,
                                                    delay : 0.5
        }}
    };

    const animationVariants_2 = {
        hidden: { opacity: 0, y: "200px"},
        visible: { opacity: 1, y:"0", transition:{
                                                    type : "just",
                                                    mass : 1,
                                                    damping : 1,
                                                    when : "beforeChildren",
                                                    staggerChildren:0.4,
                                                    duration : 1,
                                                    delay : 1
        }}
    };

    useEffect(() => {
        if (inView) {
          control.start("visible");
        } else {
          control.start("hidden");
        }
      }, [control, inView]);

    return (<>
    <div className="main w-screen relative overscroll-x-none" style={{fontFamily:"serif"}}>
        {/*NAVBAR*/}
        <div className="w-screen lg:h-20 h-14 md:h-12 lg:h-16 bg-cyan-100 z-10 pt-4 lg:pt-2 lg:z-20 fixed flex text-sky-700 overscroll-y-none top-0" style={{fontFamily:"Elgraine"}}>
            <div className="logo lg:w-1/2 w-full flex items-center justify-center lg:justify-start">
                <a href="#home" className="flex items-center lg:pl-12 lg:ml-10">
                    <img alt="logo" src={logo} className="lg:h-20 h-6"/>
                    <h1 className="lg:text-xl text-sm md:text-md">UNITYCAMP</h1>
                </a>
            </div>
            <div className="menu w-1/2 items-center justify-center hidden lg:flex">
                <div className="justify-evenly flex px-10 lg:text-lg text-xs my-auto py-auto">
                    <button className="lg:w-32 w-16 lg:h-12 h-8 rounded-lg hover:bg-[#066E9C] hover:text-white"><a href="#about" className="p-2">About us</a></button>
                    <button className="lg:w-32 w-16 lg:h-12 h-8 rounded-lg hover:bg-[#066E9C] hover:text-white"><a href="#service" className="p-2">Service</a></button>
                    <button className="lg:w-32 w-16 lg:h-12 h-8 rounded-lg hover:bg-[#066E9C] hover:text-white"><a href="#contact" className="p-2">Contact</a></button>
                </div>
            </div>
            <div className="lg:hidden block md:right-5 right-3 my-auto z-20 lg:-z-20 ">
                <motion.button animate={{
                right : sidebar ? "160px" : "20px",                
            }}
            transition={{
                type :"just" 
            }} onClick={()=>{sidebarHandler();console.log(sidebar)}} className="absolute md:top-1 top-5">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 md:w-8 md:h-8 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </motion.button>
            </div> 
            <motion.div 
            animate={{
                width : sidebar ? "200px" : "0",                
            }}
            transition={{
                type :"just" 
            }}
            className="menu md:w-1/3 w-1/2 z-10 items-center justify-center absolute right-0 bg-[#FFFFFF] pt-12 top-0 h-screen lg:hidden">
                <div className="justify-evenly flex flex-col px-2 lg:text-lg text-xs my-auto py-auto">
                    <button onClick={sidebarHandler} className="w-full text-left md:pl-2 pl-1 md:m-2 m-0 lg:h-12 h-8 rounded-lg hover:bg-[#066E9C] hover:text-white"><a href="#about" className="p-2">About us</a></button>
                    <button onClick={sidebarHandler} className="w-full text-left md:pl-2 pl-1 md:m-2 m-0 lg:h-12 h-8 rounded-lg hover:bg-[#066E9C] hover:text-white"><a href="#service" className="p-2">Service</a></button>
                    <button onClick={sidebarHandler} className="w-full text-left md:pl-2 pl-1 md:m-2 m-0 lg:h-12 h-8 rounded-lg hover:bg-[#066E9C] hover:text-white"><a href="#contact" className="p-2">Contact</a></button>
                </div>
            </motion.div>  
        </div>
        {/*HERO*/}
        <div id="home" className="lg:w-full w-full lg:mb-24 mb-16 lg:h-full" style={{fontFamily:"Elgraine"}}>
            <div className="relative w-full lg:top-16 top-14 flex justify-center">
                <img src={hero_1} className="lg:w-5/6 w-full lg:mx-auto" alt="hero"/>
                <h1 className="absolute xl:text-5xl lg:text-4xl md:text-2xl text-xs md:text-lg bottom-0 bg-[#94a3b8] text-center w-100 opacity-75 p-1 lg:px-4 lg:py-2 lg:w-5/6 w-full">Jangkauan lebih luas, masa depan berkualitas</h1>
            </div>
        </div>
        {/*About*/}
        <div className="lg:mt-20 mt-12 lg:p-10 p-2 lg:mb-16 mb-8 sm:h-72 h-96 lg:h-fit relative" id="about">
            <img src={about} className="-z-10 absolute xl:h-4/5 lg:w-full lg:h-full h-96 md:h-72 left-0 blur-sm w-full " alt="about"/>
            <div className="lg:p-10 sm:p-2 lg:mt-16 mt-2 rounded-3xl lg:h-96 lg:w-5/6 w-11/12 rounded-4xl mx-auto px-18 h-fit md:mb-32 mb-16">
                <h1 className="lg:text-5xl text-lg md:text-2xl text-white text-center lg:p-2 p-1 lg:mb-8" style={{fontFamily:"Elgraine"}}>
                    ABOUT UNITYCAMP
                </h1>
                <p className="text-center text-white lg:text-xl text-xs md:text-sm lg:m-4 m-1 lg:w-11/12 w-full mx-auto lg:tracking-wide lg:leading-8">
                Unitycamp merupakan sebuah platform sharing mahasiswa yang bertujuan untuk memudahkan para mahasiswa dalam berbagi informasi, pengalaman, dan sumber daya di lingkungan kampus di berbagai universitas yang ada di seluruh Indonesia. Platform ini dirancang untuk menghubungkan para mahasiswa dengan mahasiswa lainnya, fakultas, serta alumni, dengan harapan dapat memperkuat jaringan sosial dan profesional mereka.<br/>Hadir nya Unitycamp diharapkan bisa mengubah kebiasaan mahasiswa dalam bermedia sosial. Mahasiswa diharapkan bisa menggunakan waktu luang dengan lebih positif. Memiliki kebiasaan menggunakan Unitycamp dan mendapatkan hal-hal baru, berkembang dan lebih positif. Jadi kebiasaan membuang waktu dengan hal yang tidak menghasilkan bisa diubah dengan adanya unitycamp ini.
                </p>
            </div>
        </div>
        {/*OUR TEAM*/}
        <div ref={ref} className="lg:w-5/6 w-full mx-auto lg:mb-24 mb-12" >
            <h1 className="lg:text-5xl text-lg md:text-2xl text-black text-center lg:p-2 px-8 lg:mb-10 mb-3 lg:w-3/5 xl:w-2/5 mx-auto" style={{fontFamily:"Elgraine"}}>MEET OUR INCREDIBLE TEAM</h1>
            <div className="flex flex-wrap justify-evenly gap-y-4 lg:gap-x-8 gap-x-1 pt-4 -mt-4">
                <motion.img initial="hidden"
                animate={control}                
                variants={animationVariants}
                alt="ceo"
                  src={ceo} className="lg:p-4 p-1 bg-teal-500 lg:w-1/4 w-1/2 lg:m-0 mx-10 md:mx-16 h-auto lg:order-1"/>
                <motion.img 
                initial="hidden"
                animate={control}
                alt="coo"
                variants={animationVariants_1} src={coo} className="lg:p-4 p-1 bg-teal-500 lg:w-1/4 w-1/3 h-auto"/>
                <motion.img 
                initial="hidden"
                animate={control}
                alt="cto"
                variants={animationVariants_1} src={cto} className="lg:p-4 p-1 bg-teal-500 lg:w-1/4 w-1/3 h-auto lg:order-1"/>
                <motion.img 
                initial="hidden"
                animate={control}
                alt="cmo_1"
                variants={animationVariants_2} src={cmo_1} className="lg:p-4 p-1 bg-teal-500 lg:w-1/4 w-1/3 h-auto lg:order-1"/>
                <motion.img 
                initial="hidden"
                animate={control}
                alt="cmo_2"
                variants={animationVariants_2} src={cmo_2} className="lg:p-4 p-1 bg-teal-500 lg:w-1/4 w-1/3 h-auto lg:order-1"/>
            </div>
        </div>
        {/*Product */}
        <div className="w-full xl:w-5/6 lg:mx-auto lg:p-6 p-2 lg:py-12 py-6 lg:mb-8 mb-4 lg:pt-20 pt-2 flex flex-col flex-wrap lg:h-[52rem]" id="service" style={{}}>
                <h1 className="lg:text-5xl lg:text-left text-lg md:text-2xl text-center w-5/6 lg:pl-6 lg:p-2 pb-1 lg:m-2 lg:w-2/3 mx-auto" style={{fontFamily:"Elgraine"}}>SEGERA DAFTAR DAN BERGABUNG DENGAN UNITYCAMP</h1>
                <img src={hp} alt="hp" className="lg:pt-4 lg:mt-4 lg:w-1/3 w-2/5 mx-auto lg:m-0 lg:-order-1"/>
                <div className="flex flex-col lg:gap-3 my-1 lg:pt-1 lg:w-2/3">
                    <h3 className="lg:text-xl text-sm text-center md:text-lg lg:text-left lg:w-full w-3/4 lg:m-2 mx-auto pl-0" style={{fontFamily:"Elgraine"}}>DOWNLOAD DAN DAPATKAN PROMO MENARIK</h3>
                    <div className="flex lg:w-full flex-col m-0 lg:flex-nowrap lg:h-fit h-[20rem]">
                        <h3 className="mt-4 align-middle lg:text-xl md:p-2 text-sm md:text-md text-left w-full xl:w-3/4 md:mx-auto md:w-5/6 lg:w-full lg:m-2 m-1 lg:p-3 p-1 h-fit lg:pl-3 bg-blue-300 rounded" style={{fontFamily:"Elgraine"}}>Gratis satu bulan layanan untuk pengguna pertama kali</h3>
                        <h3 className="align-middle lg:text-xl text-sm md:p-2 md:text-md text-left w-full xl:w-3/4 md:mx-auto md:w-5/6 lg:w-full lg:m-2 m-1 lg:p-3 p-1 h-fit lg:pl-3 bg-blue-300 rounded" style={{fontFamily:"Elgraine"}}>Paket premium gratis khusus untuk mahasiswa berprestasi</h3>
                        <div className="p-1 bg-white w-full border-2 border-black rounded relative md:w-5/6 md:mx-auto lg:w-3/4 lg:m-2 m-1 h-[14rem] xl:w-1/2">
                            <h3 className="bg-teal-600 rounded-3xl lg:text-lg text-sm md:text-md px-2 p-1 text-white pl-7" style={{fontFamily:"Elgraine"}}>PAKET PREMIUM</h3>
                            <div className="lg:pl-10 lg:p-1 pt-1 lg:text-lg text-sm md:text-md md:p-2">
                                <h4>Masa penggunaan selama satu bulan</h4>
                                <h4>Dapat menambah teman baru</h4>
                                <h4>Jangkauan lebih luas</h4>
                                <h4>Fitur pencarian spesifik</h4>
                            </div>
                            <div className="flex justify-between md:justify-evenly p-1 absolute bottom-2 w-full lg:p-3 pb-0 -m-1" style={{fontFamily:"Elgraine"}}>
                                <h2 className="w-4/12 h-fit md:text-lg my-auto lg:text-xl text-md lg:p-2 lg:pl-5">Rp25,000.-</h2>
                                <button className="w-6/12 bg-teal-600 rounded-lg lg:p-2 py-1 lg:text-lg text-sm text-white"><a href="#contact">PESAN SEKARANG</a></button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        {/* Service*/}
        <div className="lg:w-5/6 w-full mx-auto lg:mb-16 mb-6" style={{fontFamily:"Elgraine"}}>
            <h1 className="lg:text-5xl text-xl md:text-2xl m-2 pl-3 text-center lg:mb-6 mb-2" >Our Product</h1>
            <div className="flex w-full justify-evenly flex-wrap mx-auto">
                <div className="lg:w-1/5 w-5/12 rounded-lg shadow-lg m-1">
                    <img alt="chat" className="p-1 mb-2 w-full"src={fitur_chat}/>
                    <h3 className="text-xl text-center p-1 m-2">Fitur Chat</h3>
                </div>
                <div className="lg:w-1/5 w-5/12 rounded-lg shadow-lg m-1">
                    <img alt="diskusi" className="p-1 mb-2"src={fitur_diskusi}/>
                    <h3 className="text-xl text-center p-1 m-2">Fitur Diskusi</h3>
                </div>
                <div className="lg:w-1/5 w-5/12 rounded-lg shadow-lg m-1">
                    <img alt="search" className="p-1 mb-2"src={fitur_pencarian}/>
                    <h3 className="text-xl text-center p-1 m-2">Fitur Pencarian</h3>
                </div>
                <div className="lg:w-1/5 w-5/12 rounded-lg shadow-lg m-1">
                    <img alt="vid" className="p-1 mb-2"src={video_random}/>
                    <h3 className="text-xl text-center p-1 m-2">Video Random</h3>
                </div>
            </div>
        </div>
        {/*Footer */}
        <div className="w-full mx-auto" id="contact" style={{backgroundImage:"linear-gradient(#FFFFFF,#40A8D6)"}}>
            <div className="flex flex-wrap lg:h-72 lg:items-end pb-8 lg:pt-auto pt-12 lg:justify-between">
                <div className="lg:ml-20 ml-4">
                    <h2 className="lg:m-4 m-2 mb-1 lg:text-3xl text-md md:text-xl" style={{fontFamily:"Elgraine"}}>Contact us at</h2>
                    <div className="flex lg:m-2 m-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-6 lg:h-6 lg:mx-2 w-4 h-4 mx-1 my-auto">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                        </svg>
                        <h3 className="mx-3 lg:text-lg text-md">phone : 085697080992</h3>
                    </div>
                    <div className="flex lg:m-2 m-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-6 lg:h-6 lg:mx-2 w-4 h-4 mx-1 my-auto">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                        <h3 className="mx-3 lg:text-lg text-md">email : weunitycamp@gmail.com</h3>
                    </div>
                    <div className="flex lg:m-2 m-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="lg:w-6 lg:h-6 lg:mx-2 w-4 h-4 mx-1 my-auto">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                        <h3 className="mx-3 lg:text-lg text-md">address : Rawamangun, Jakarta Timur, Dki Jakarta</h3>
                    </div>
                </div>
                <div className="lg:mr-20 m-4 flex flex-col flex-wrap lg:flex-nowrap items-center h-32 lg:h-fit">
                    <h3 className="m-2 lg:w-auto w-2/3 lg:text-xl">Join Our Discord Channel</h3>
                    <a className="m-2 lg:order-1 lg:w-auto w-2/3 lg:text-xl" href="https://discord.gg/pEjX2YJt">https://discord.gg/pEjX2YJt</a>
                    <a className="lg:m-2 mx-4 py-2 lg:p-0" href="https://discord.gg/pEjX2YJt"><img alt="dc" src={dc} className="w-16"/></a>
                </div>
            </div>
        </div>

    </div>
    </>)
}