import React from 'react';
import logo from './logo.svg';
import './App.css';
import Pages from "./components/Pages"
import './Elgraine-Light.otf';

function App() {
  return (
    <Pages/>
  );
}

export default App;
